<template>
  <div>
    <dashboard-page-title :showAddBtn="false" >المتاجر</dashboard-page-title>
    <main-table :baseURL="SFZ_API_LINK"  :fields="fields" list_url="admin/stores" :additional-url="`&status=processing`" :reloadData="reloadTable"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import marketPlaceServices from '../services/marketPlace'
import { SFZ_API_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      SFZ_API_LINK,
      fields: [
        { label: 'صوره الشعار', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'صوره الغلاف', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'تفعيل',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              icon: 'las la-check',
              color: 'success-light',
              text: 'تفعيل',
              actionName: 'activeMerchent',
              actionParams: ['id']
            }
          ]
        }
      ],
      reloadTable: false
    }
  },
  methods: {
    activeMerchent (obj) {
      marketPlaceServices.acceptMerchent(obj.id, { status: 'completed' }).then(res => {
        core.showSnackbar('success', 'تم القبول')
        this.reloadTable = true
      })
    }
  },
  created () {
    this.$root.$on('activeMerchent', this.activeMerchent)
  },
  beforeDestroy () {
    this.$root.$off('activeMerchent')
  }
}
</script>
